<template>
  <div class="bg_box">
    <!-- <div class="img_boxsss"  >
            <div class="nei_box">
                <div class="title">全部</div>
                <div class="content"> View all videos</div>
            </div>
        </div> -->
    <div
      class="img_boxsss"
      @click="type_fen(item.id)"
      v-for="(item, index) in arr"
      :key="index"
    >
      <div class="nei_box">
        <div class="title">{{ item.name }}</div>
        <div class="content">{{ item.names }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { types_top } from "../../views/common/js/api";
export default {
  data() {
    return {
      currentDate: new Date(),
      arr: [],
    };
  },
  methods: {
    type_fen(ids) {
      //func: 是父组件指定的传数据绑定的函数，this.msg:子组件给父组件传递的数据
      this.$emit("ids", ids);
    },
    tops() {
      types_top({})
        .then((res) => {
          console.log(res.data);
          var b = { name: "全部" };
          res.data.unshift(b);
          this.arr = res.data;
          this.arr[0]["names"] = "View all videos";
          this.arr[1]["names"] = "High Enterprises";
          this.arr[2]["names"] = "Finance & taxation";
          this.arr[3]["names"] = "Talent guidance";
          this.arr[4]["names"] = "Science Finance";
          this.arr[5]["names"] = "Torch statistics";
          this.arr[6]["names"] = "Intellectual property";
          this.arr[7]["names"] = "Other";
        })
        .catch((err) => {});
    },
  },
  created() {
    this.tops();
  },
};
</script>

<style lang='less' scoped>

.nei_box {
  color: #fff;
  margin: 20px 10px;
  line-height: 30px;
  .title {
    font-weight: 600;
    color: rgba(255, 255, 255, 100);
    font-size: 20px;
  }
  .content {
    color: rgba(255, 255, 255, 100);
    font-size: 12px;
  }
}
.nei_box:hover {
   transform: scale(1.05);
}
.bg_box {
  display: flex;
}
.img_boxsss:hover {
   transform: scale(1.05);
}
.img_boxsss {
  margin-left: 55px;
  width: 171px;
  height: 100px;
  background-image: url("~@/assets/img/home/bg_card_two.png");
  background-size: 100% 100%;
  cursor: pointer;

  img {
    width: 330px;
    height: 190px;
  }
}
.card-item {
  padding: 10px;
  box-sizing: border-box;
}
.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
</style>
