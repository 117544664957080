<template>
  <div class="container">
    <div class="nei_container">
   
      <!--轮播-->
      <div class="home">
        <div id="father" class="grid-content bg-purple">
          <div
            id="swiper-box"
            class="swiper-box"
            :style="{ transform: 'translateX(' + currentOffset + 'px)' }"
          >
            <cards @ids="getMsgFormSon" :width="width"></cards>
          </div>
          <i class="el-icon-arrow-left" @click="left"></i>
          <i class="el-icon-arrow-right" @click="right"></i>
        </div>
      </div>
      <div class="title_flexs">
        <div class="line"></div>
        <div class="text">热门播放</div>
        <div class="line"></div>
      </div>
      <div class="box">
        <div
          class="re_box"
          v-for="(item, index) in videoes"
          :key="index"
          @click="shi_detail(item, item.video)"
        >
          <div class="shi_ping">
            <div class="img_box">
               <img :src="item.cover|ImgFilter"/>
              <!-- <video
                :preload="preload"
                :poster="videoImg"
                align="center"
                :controls="controls"
                :autoplay="autoplay"
              >
                <source :src="item.video | ImgFilter" type="video/mp4" /> -->
                <!--<img :src="detail.one|ImgFilter"/>-->
              <!-- </video> -->
            </div>
            <div class="title_ke">{{ item.title }}</div>
            <div class="content_ke">
              适用地区：{{ item.provincename }}
              <!-- {{ item.cityname
              }}{{ item.areaname }} -->
            </div>
            <div class="two_ke">
              <div>讲师：{{ item.teacher }}</div>
              <div v-if="item.create_time != null">
                发布时间：{{ item.create_time.substring(0, 10) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="jia_zai" @click="gengs_duo">加载更多>></div>
    </div>
  </div>
</template>

<script>
 
import cards from "../../../components/common/cards";
import { lesson_type, types_top } from "../../common/js/api";
export default {
  name: "kehuixy",
  components: {
    cards, 
  },
  data() {
    return {
      videoSrc: "../../../assets/video/one.mp4",
      videoImg: "../../../../static/full_res.jpg",
      playStatus: "",
      muteStatus: "",
      isMute: true,
      isPlay: false,
      widths: "340", // 设置视频播放器的显示宽度（以像素为单位）
      height: "160", // 设置视频播放器的显示高度（以像素为单位）
      preload: "auto", //  建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
      controls: true, // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
      autoplay: false,
      currentOffset: 0,
      width: "",
      fatherWith: 0,
      box: "",
      input1: "",
      input4: "", //搜索关键字
      videoes: [],
      tiao_shu: 6,
      msgFormSon: "",
      total: 0,
      fullscreenLoading: false,
    };
  },
  filters: {
    ImgFilter(value) {
      return "http://hout.kehui.cloud" + value;
    },
  },
  created() {
     this.msgFormSon = this.$route.query.id;
    this.kes_hui();
  },
  mounted() {
    this.setCardWidth();
    this.box = document.querySelector("#swiper-box");
    window.addEventListener("resize", () => {
      this.currentOffset = 0;
      this.setCardWidth();
    });
  },
  methods: {
    getMsgFormSon(data) {
      this.msgFormSon = data;
      this.kes_hui();
    },
    search_box() {
      this.kes_hui();
    },
    gengs_duo() {
      // const loading = this.$loading({
      //     lock: true,
      //     text: 'Loading',
      //     spinner: 'el-icon-loading',
      //     background: 'unset'
      // });
      // setTimeout(() => {
      //     loading.close();
      // }, 2000);
      if (this.total == this.videoes.length) {
        this.$message({
          message: "已经加载到底了",
          type: "success",
        });
      }
      
      this.tiao_shu = this.tiao_shu + 6;
      this.kes_hui();
    },
    kes_hui() {
      lesson_type({
        page: "",
        limits: this.tiao_shu,
        title: this.input4,
        type: this.msgFormSon,
      })
        .then((res) => {
          this.total = res.data.total;
          this.videoes = res.data.data;
        })
        .catch((err) => {});
    },
    shi_detail(vi, vi1) {
      this.$router.push({
        path: "/courseDetail",
        query: {
          id: vi.id,
          video: vi1,
        },
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    setCardWidth() {
      const father = document.querySelector("#father");
      this.fatherWith = father.clientWidth;
      console.log("fatherWidth", this.fatherWith);
      this.width = (this.fatherWith - 20) / 5 + "px";
    },

    right() {
      console.log(this.currentOffset);
      if (this.currentOffset <= (-(this.fatherWith - 20) / 5) * (10 - 5))
        return;
      this.currentOffset -= (this.fatherWith - 20) / 5;
    },
    left() {
      console.log(this.currentOffset);
      if (this.currentOffset >= -2) return;
      this.currentOffset += (this.fatherWith - 20) / 5;
    },
  },
};
</script>

<style scoped lang="less">
video {
  margin: 0 auto;
  width: 340px;
  height: 160px;
}
/deep/.el-input__icon {
  font-size: 30px;
  line-height: 70px;
}
/deep/.el-input__inner {
  font-size: 20px;
  padding-left: 40px;
  width: 945px;
  height: 72px;
  line-height: 20px;
  border-radius: 10px;
  border: 1px solid rgba(187, 187, 187, 100);
}
/deep/.el-input {
  margin: 30px 10% 0px 10%;
}
.title_flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .text {
    font-size: 48px;
    color: #3892eb;
    font-weight: 600;
  }
  .line {
    margin: 100px;
    width: 60px;
    height: 1px;
    background-color: #3892eb;
  }
}
.title_flexs {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .text {
    font-size: 48px;
    color: #3892eb;
    font-weight: 600;
  }
  .line {
    /*margin:100px;*/
    margin: 0px 100px;
    width: 60px;
    height: 1px;
    background-color: #3892eb;
  }
}
.container {
  background-color: #f8f9fa;
  width: 100%;
  height: auto;
  overflow: hidden;
  .nei_container {
    width: 1200px;
    margin: 0 auto;
    .bg-purple {
      overflow: hidden;
      position: relative;
      /*padding-left: 10px;*/
    }
    .home {
      margin-top: 30px;
      .swiper-box {
        display: flex;
        width: auto;
        transition: all 0.2s;
      }
    }

    [class^="el-icon-arrow-"] {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 30px;
      padding: 10px 0;
      background-color: #999;
      cursor: pointer;
    }
    .el-icon-arrow-left {
      left: -7px;
    }
    .el-icon-arrow-right {
      right: -7px;
    }
    .search_box {
      margin: 0 auto;
      display: flex;
      align-items: center;
      .search {
        z-index: 9999999;
        margin-top: 30px;
        margin-left: -30px;
        width: 106px;
        height: 43px;
        line-height: 40px;
        border-radius: 4px;
        background-color: rgba(56, 146, 235, 100);
        color: rgba(235, 245, 254, 100);
        font-size: 20px;
        text-align: center;
        font-family: Microsoft Yahei;
        border: 1px solid rgba(255, 255, 255, 100);
      }
    }
    .box {
      display: flex;
      flex-wrap: wrap;
      /*.re_box:not(:first-child) {*/
      /*margin-left:30px;*/
      /*}*/
      margin: 0 auto;
      .re_box:hover   {
         transform: scale(1.05);
      }
      .re_box {
        box-sizing: border-box;
        margin-right: 25px;
        width: 375px;
        height: 286px;
        line-height: 20px;
        border-radius: 5px;
        box-shadow: 0 0 30px 3px rgba(119,118,118,0.33);
        margin-top: 30px;
        .shi_ping {
          width: 91%;
          margin: 4% auto;
          .img_box {
            width: 340px;
            height: 160px;
            position: relative;
            .fu_fe {
              position: absolute;
              top: 10px;
              left: 10px;
              width: 56px;
              height: 22px;
              img {
                width: 56px;
                height: 22px;
              }
            }
            img {
              width: 340px;
              height: 160px;
            }
          }
          .title_ke {
            margin: 10px 0px;
            color: rgba(0, 5, 10, 100);
            font-size: 18px;
          }
          .content_ke {
            margin: 10px 0px;
            color: rgba(181, 181, 181, 100);
            font-size: 14px;
          }
          .two_ke {
            display: flex;
            justify-content: space-between;
            color: rgba(0, 0, 0, 1);
            font-size: 14px;
          }
        }
        &:nth-child(4) {
          margin-left: 0px;
        }
      }
    }
    .jia_zai {
      margin: 20px auto;
      width: 150px;
      height: 27px;
      color: rgba(56, 146, 235, 100);
      font-size: 18px;
      text-align: left;
      font-family: SourceHanSansSC-regular;
    }
  }
  .containers {
    margin-top: 30px;
  }
}
@media screen and (max-width: 1200px) {
	.w{
		width: 98%;
	}
	.container .nei_container{
		width: 98%;
	}
}
</style>