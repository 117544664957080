<template>
  <div>
    <con-tab></con-tab>
    <!--轮播图-->
    <home-swiper :imgList="imgList" class="swiper_class"></home-swiper>
    
    <!-- <search class="nei_box"></search> -->
    <need-nei></need-nei>
  </div>
</template>

<script>
import ConTab from "../../components/common/ConTab";
import NeedNei from "./base/kehuixy";
import HomeSwiper from "../../components/common/Swiper";
// import search from "./SearchCom/search";
import { lun_xin } from "../common/js/api";
import FromList from "@/common/input";
export default {
  name: "gaoxiao",
  components: {
    HomeSwiper,
    ConTab,
    NeedNei,
    FromList,
    // search,
  },
  data() {
    return {
       search: "",
      imgList: [],
    };
  },
  created() {
    this.lun_bo();
  },
  methods: {
    //机构云轮播
    lun_bo() {
      lun_xin({})
        .then((res) => {
          this.imgList = res.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="less">
.swiper_class {
  position: relative;
}
.img_box {
  width: 1920px;
  height: 451px;
  img {
    width: 1920px;
    height: auto;
  }
}
.nei_box {
  position: absolute;
  top: 200px;
  z-index: 9999;
}
</style>